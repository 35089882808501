@use "../../common/fontssetup" as *;
@import '../../common/variables';

.itineraries-details-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .mb-best-price-btn  {
        display: none;

        @include sm-desktop {
            box-sizing: border-box;
            background: $primary-beige;
            position: fixed;
            z-index: 100;
            bottom: 70px;
            width: calc(100% - 40px);
            margin: 0 20px;
            padding: 10px 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 50px;
            background: var(--Beige, #F4F0E8);
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);

        }

        @include sm-mb {
            bottom: 140px;
        }
        .icon {
            svg { display: block; }
        }
        
        .from-text {
            color: $primary-sand;
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;

            @include sm-desktop {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .price-item {

            .price {
                font-size: 36px;
                line-height: 44px;
                font-weight: 500;

                @include sm-desktop {
                    font-size: 24px;
                    line-height: normal;
                }
            }
            .per {
                font-size: 14px;
                line-height: 28px;

                @include sm-desktop {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }


    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        .best-price-section {
            width:30%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;

            @include sm-desktop {
                position: fixed;
                top: 110%;
                left: 0;
                width: 100%;
                height: 100vh;
                padding: 40px;
                background-color: $primary-beige;
                z-index: 101;
                transition: all 1s ease;

                &.active {
                    top: 0;
                    transition: all 1s ease;
                }
            }
                
            .close-section {
                text-align: right;
            }

            .best-price-container {
                background-color: $primary-beige;
                border-radius: 50px;
                overflow: hidden;

                @include sm-desktop {
                    border-radius: 0;
                }

                .content-details {
                    padding: 40px;

                    @include sm-desktop {
                        padding: 0;
                        padding-top: 30px;
                    }

                    .title {
                        font-size: 24px;
                        line-height: 32px;

                        @include sm-desktop {
                            font-size: 20px;
                        }
                    }
                    
                    .line-section {
                        padding: 20px 0;
            
                        .line {
                            width: 100%;
                            height: 2px;
                            background-color: $primary-sand;
                        }
                    }
                    .from-text {
                        color: $primary-sand;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 700;

                        @include sm-desktop {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .price-item {
                        padding-bottom: 20px;

                        .price {
                            font-size: 36px;
                            line-height: 44px;
                            font-weight: 500;

                            @include sm-desktop {
                                font-size: 24px;
                                line-height: normal;
                            }
                        }
                        .per {
                            font-size: 14px;
                            line-height: 28px;

                            @include sm-desktop {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                    .view-all-rate-btn {
                        box-sizing: border-box;
                        width: 100%;
                        padding: 6px 20px;
                        border-radius: 300px;
                        text-align: center;
                        background: $primary-sand;
                        color: $primary-white;
                        font-size: 20px;
                        line-height: 30px;
                        cursor: pointer;

                        @include sm-desktop {
                            font-size: 16px;
                            line-height: normal;
                        }
                    }
                }
                .img-section {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);

                    @include sm-desktop {
                        display: none;
                    }

                    .img-item {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        .content-details-section {
            width: 70%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;

            &.beige-bg {
                background: $primary-beige;
            }
            

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 0;
            }

            .back-btn {
                display: flex;
                align-items: center;
                padding-bottom: 24px;
                text-decoration: none;

                .icon {
                    svg { display: block; }
                }

                .name {
                    color: $primary-sand;
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .content-desp {
                font-size: 18px;
                line-height: 28px;

                p {
                    margin: 0;
                }

                strong {
                    font-weight: 700;
                }

            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }
            
            .icon-title {
                display: flex;
                align-items: center;
                column-gap: 16px;

                .title-name {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }

            .night-details {
                .item-details {
                    padding: 5px 20px;
                    background: $primary-sand;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
                .item-remark {
                    padding-left: 10px;
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .expect-section {
                box-sizing: border-box;
                padding-right: 40px;
                margin-bottom: 60px;
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                .section-details {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }

            .highlight-section {
                box-sizing: border-box;
                margin-left: -40px;
                padding: 60px 40px;
                background: $primary-beige;
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                @include sm-desktop {
                    margin-left: 0;
                }

                .section-details {
                    font-size: 18px;
                    line-height: 28px;

                    ul {
                        margin: 0;
                        padding: 0;
                        padding-left: 20px;
                    }
                }
            }
            .mb-img-section {
                display: none;
                @include sm-desktop {
                    display: flex;
                    flex-direction: column;
                    row-gap: 24px;
                    padding: 24px 0;
                }

                .img-item {
                    display: block;
                    width: 100%;
                    border-radius: 50px;
                }
            }
        }
    }
    .details-tab-section {
        margin: 100px auto;
        padding: 0 40px;
        max-width: 1440px;

        @include sm-desktop {
            margin-bottom: 0;
            padding: 0 20px 100px;
        }

        .tab-btn-section-mb {
            display: none;

            @include sm-desktop {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            
            .item-selection {
                -moz-appearance:none; /* Firefox */
                -webkit-appearance:none; /* Safari and Chrome */
                appearance:none;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z' fill='black'/%3E%3C/svg%3E");
                background-repeat:no-repeat;
                background-position:calc(100% - 20px);
                background-size: 24px;
                
                width: 100%;
                padding: 10px 20px;
                // max-width: 400px;
                font-size: 16px;

                &:focus-visible {
                    outline: none;
                }

                option:checked  { 
                    background-color: $primary-sand2;
                    color: $primary-white;
                }

                option:hover,
                option:focus,
                option:active,
                option:checked   { 
                    background-color: $primary-sand2;
                    color: $primary-white;
                }
            }
        }
        

        .tab-btn-section {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                display: none;
            }

            .btn-item {
                box-sizing: border-box;
                padding: 10px 40px;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                border-bottom: 1px solid $primary-sand;
                cursor: pointer;

                &.active {
                    color: $primary-white;
                    background: $primary-dark-brown;
                }
            }
        }

        .tab-content {
            display: none;
            padding-top: 60px;

            &.active {
                display: block;
            }

            &.daytoday-content {
                .day-item-container {
                    .day-item {
                        padding: 20px 0;
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        column-gap: 48px;
                        border-bottom: 1px solid $primary-sand;

                        &:last-child {
                            border-bottom: 0;
                        }

                        @include sm-desktop {
                            display: flex;
                            flex-direction: column;
                            row-gap: 30px;
                        }

                        .item-img {
                            width: 100%;
                            border-radius: 50px;
                        }

                        .day-item-details {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            row-gap: 15px;

                            .item-title {
                                @include title-font;
                                font-size: 24px;
                                line-height: 28px;
                                font-weight: 700;
                            }
                            .desp {
                                font-size: 18px;
                                line-height: 28px;
                                display: flex;
                                flex-direction: column;
                                row-gap: 15px;

                                p {
                                    margin: 0;
                                }

                                h6 {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 28px;
                                    margin: 0;
                                }
                            }
                            .fight-details {
                                .desp {
                                    font-size: 18px;
                                    line-height: 28px;
                                    padding-bottom: 15px;
                                }
                                .details-item {
                                    display: grid;
                                    grid-template-columns: auto 2fr auto 2fr;
                                    column-gap: 2px;
                                    margin-bottom: 2px;

                                    @include sm-desktop {
                                        grid-template-columns: auto 2fr;
                                        grid-template-rows: auto;
                                        margin-bottom: 30px;
                                        row-gap: 2px;
                                    }

                                    .item {
                                        padding: 10px 30px;
                                        background: $primary-beige;
                                        font-size: 18px;
                                        line-height: 28px;
                                        font-weight: 700;
                                    }
                                }
                            }
                            .meal-details {
                                margin: 20px 0;
                                display: flex;
                                align-items: center;
                                column-gap: 15px;

                                .details {
                                    font-size: 18px;
                                    line-height: 28px;
                                }
                            }
                            .stay-details {
                                display: flex;
                                align-items: center;
                                column-gap: 60px;
                                border-top: 1px solid $primary-sand;
                                
                                @include sm-desktop {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    width: 100%;
                                }
                                
                                .stay-item {
                                    margin: 20px 0;
                                    display: flex;
                                    align-items: center;
                                    column-gap: 15px;
    
                                    .type {
                                        font-size: 18px;
                                        line-height: 28px;
                                        font-weight: 700;
                                    }
                                    .details {
                                        font-size: 18px;
                                        line-height: 28px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.wheretostay-content {
                .stay-item-container {
                    .stay-item {
                        padding-bottom: 100px;

                        .title {
                            font-size: 36px;
                            line-height: 44px;
                            font-weight: 500;
                            text-align: center;
                            padding-bottom: 40px;

                            @include sm-desktop {
                                font-size: 24px;
                                line-height: normal;
                            }
                        }

                        .stay-choice-section {
                            display: flex;
                            align-items: stretch;
                            flex-wrap: wrap;
                            column-gap: 60px;
                            row-gap: 60px;
                            width: 100%;

                            @include sm-desktop {
                                flex-direction: column;
                                row-gap: 20px;
                            }

                            .stay-choice-item {
                                width: calc(50% - 30px);
                                border-radius: 50px;
                                background: $primary-beige;
                                @include sm-desktop {
                                    width: 100%;
                                }

                                .item-img-container {
                                    display: block;
                                    width: 100%;
                                    border-radius: 50px 50px 0px 0px;
                                    position: relative;
                                    overflow: hidden;

                                    .item-image {
                                        display: block;
                                        width: 100%;
                                        border-radius: 50px 50px 0px 0px;
                                    }
                                }
                                
                                .slick-dots {
                                    display: block;
                                    bottom: 23px;

                                    li {
                                        button {
                                            &::before {
                                                font-size: 10px;
                                                color: $primary-white;
                                                opacity: 1;
                                                filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.10));
                                            }
                                        }

                                        &.slick-active {
                                            button {
                                                &::before {
                                                    color: $primary-sand;
                                                    opacity: 1;
                                                }
                                            }

                                        }
                                    }

                                }

                                .item-content {
                                    border-radius:0px 0px 50px 50px;
                                    padding: 30px 40px;
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: 15px;

                                    .place {
                                        display: flex;
                                        align-items: center;
                                        column-gap: 10px;

                                        .name {
                                            font-size: 18px;
                                            line-height: 28px;

                                            @include sm-desktop {
                                                font-size: 16px;
                                                line-height: 24px;
                                            }
                                        }
                                    }
                                    .name-and-night {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .name-item {
                                            .name {
                                                font-size: 24px;
                                                line-height: 32px;
                                                font-weight: 500;

                                                @include sm-desktop {
                                                    font-size: 20px;
                                                    line-height: 32px;
                                                }
                                            }
                                            .remark {
                                                font-size: 14px;
                                                line-height: 28px;

                                                @include sm-desktop {
                                                    font-size: 12px;
                                                    line-height: 18px;
                                                }
                                            }
                                        }

                                        .night-item {
                                            padding: 5px 20px;
                                            background: $primary-sand2;
                                            font-size: 24px;
                                            line-height: 32px;
                                            font-weight: 500;
                                        }
                                    }

                                    .desp {
                                        font-size: 18px;
                                        line-height: 28px;

                                        @include sm-desktop {
                                            font-size: 16px;
                                            line-height: 24px;
                                        }
                                    }

                                    .rate-section {
                                        display: flex;
                                        align-items: center;
                                        column-gap: 15px;

                                        .rate-start {
                                            svg {display: block;}
                                        }

                                        .name {
                                            font-size: 18px;
                                            line-height: 28px;
                                            

                                            @include sm-desktop {
                                                font-size: 16px;
                                                line-height: 24px;
                                            }
                                        }
                                        
                                    }
                                }
                            }
                        }

                    }
                }
            }

            &.rate-content {
                &.active {
                    display: flex;
                    flex-direction: column;
                    row-gap: 67px;
    
                }

                .title-table-section {
                    display: flex;
                    flex-direction: column;
                    row-gap: 32px;

                    .section-title {
                        font-size: 36px;
                        line-height: 44px;
                        font-weight: 500;
                    }

                    .rate-table-container {
                        overflow-x: auto;
                    }

                    .rate-table {
                        min-width: 900px;
                        overflow: hidden;

                        .table-item {
                            display: grid;
                            grid-template-columns: repeat(4,1fr);

                            div {
                                padding: 10px 20px;
                                text-align: center;
                                min-height: 60px;
                                border-bottom: 1px solid $primary-sand2;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                font-size: 18px;
                                line-height: 28px;
                            }

                            &.table-title {
                                background: $primary-beige;

                                div {
                                    border-bottom: 0px;

                                    .name {
                                        font-size: 24px;
                                        line-height: 32px;
                                        font-weight: 500;
                                    }

                                    .remark {
                                        font-size: 14px;
                                        line-height: 28px;
                                    }
                                }
                            }
                        }
                    }

                    .section-remark {
                        font-size: 14px;
                        line-height: 28px;
                        
                        p {
                            margin: 0;
                        }
                    }
                }

                .icon-desp-section  {
                    .icon-title {
                        display: flex;
                        align-items: center;
                        column-gap: 16px;
                        padding-bottom: 24px;

                        .icon {
                            svg {display: block;}
                        }

                        .name {
                            font-size: 24px;
                            line-height: 32px;
                            font-weight: 500;
                        }

                    }
                    .desp {
                        ul {
                            margin: 0;
                            padding: 0;
                            padding-left: 20px;

                            li {
                                font-size: 14px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

}