@use "../../common/fontssetup" as *;
@import '../../common/variables';

.aboutsafari-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .content-section {
        padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        margin: 0 auto;

        .recommend-section {
            width:25%;
            box-sizing: border-box;
            padding: 0 40px;

            @include sm-desktop {
                display: none;
            }

            .page-list {
                list-style-type: none;
                position: sticky;
                top: 15%;

                .page-list-item {
                    box-sizing: border-box;
                    padding: 20px 0;
                    border-bottom: 2px solid $primary-sand;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;

                    &.active {
                        color: $primary-sand;
                    }
                    .item-link {
                        color: inherit;
                        text-decoration: none;

                        &:hover {
                            color: $primary-sand;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .content-details-section {
            width: 75%;
            box-sizing: border-box;
            padding: 0 40px;

            @include sm-desktop {
                width: 100%;
                padding: 0 24px;
            }

            .details-sub-section {
                padding: 60px 0;
                
                &:first-child {
                    padding-top: 0;
                }
            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            h3{
                padding-bottom: 24px;
                font-size: 24px;
                font-weight: 500;
                margin: 0;
            }
            p {
                font-size: 18px;
                line-height: 28px;
                margin: 0;
            }
            .desp {
                padding-bottom: 24px;
                font-size: 18px;
                line-height: 28px;

                strong {
                    font-weight: 700;
                }
            }

            .map-btn {
                &-container {
                    display: flex;
                }
                padding: 6px 20px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                border-radius: 300px;
                border: 1px solid $primary-sand;
                background: $primary-white;
                text-decoration: none;

                .btn-text {
                    color: $primary-black;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .icon-title {
                display: flex;
                align-items: center;
                column-gap: 16px;
                padding-bottom: 24px;

                .icon {
                    width: 25px;
                    height: 25px;
                }

                .title-text {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .summary-item {
                display: flex;
                align-items: center;
                column-gap: 48px;
                padding-bottom: 24px;

                @include sm-desktop {
                    flex-direction: column;
                    row-gap: 24px;
                }

                .img-section {
                    width: 45%;

                    @include sm-desktop {
                        width: 100%;
                    }

                    img {
                        display: block;
                        width: 100%;
                        border-radius: 50px;
                    }
                }

                .summary-details {
                    width: 55%;
                    font-size: 18px;
                    line-height: 28px;

                    @include sm-desktop {
                        width: 100%;
                    }

                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 500;
                        padding-bottom: 24px;
                        margin: 0;
                    }

                    p {
                        margin: 0;
                    }

                    ul {
                        margin: 0;
                        padding: 20px;
                    }
                }
            }

            .moment-item {
                .moment-title {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 24px;

                    .title-item {
                        position: relative;
                        font-size: 18px;
                        line-height: 28px;
                        padding-bottom: 2px;
                        font-weight: bold;
                    }

                    .text-underline {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background: $primary-sand;
                    }
                }
                .moment-desp {
                    font-size: 18px;
                    line-height: 28px;
                    padding-bottom: 24px;
                }
                .momment-img-section {
                    width: 100%;

                    .gallery{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 60px;
                        row-gap: 24px;
                        padding-bottom: 24px;

                        @include sm-desktop {
                            grid-template-columns: 1fr;
                        }
                    }

                    .gallery-item {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;

                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50px;
                        }
                    }

                    img {
                        border-radius: 50px;
                    }

                }
            }
        }
    }

}