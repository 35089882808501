@use "../../common/fontssetup" as *;
@import '../../common/variables';

.aboutus-moments-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 24px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    
    .page-info {
        box-sizing: border-box;
        max-width: 1440px;
        margin: 0 auto;
        padding: 60px 40px;

        @include sm-desktop {
            padding: 60px 24px;
        }

        .info-title {
            @include title-font;
            font-size: 48px;
            text-align: center;
        }

        .line-section {
            padding: 24px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                width: 100px;
                height: 2px;
                background-color: $primary-sand;
                opacity: 0.5;

            }
        }

        .desp {
            text-align: center;
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
        }
        
    }

    .moment-list {
        box-sizing: border-box;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 40px;
        padding-bottom: 60px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 60px;
        row-gap: 40px;
        
        @include sm-desktop {
            grid-template-columns: 1fr;
            padding: 0 24px 60px;
        }

        .moment-item {
            overflow: hidden;
            border-radius: 50px;
            background: $primary-beige;

            
            .item-img-container {
                height: 40vh;
                overflow: hidden;

                .item-image {
                    display: block;
                    width: 100%;
                }
            }

            .item-details {
                padding: 30px 40px;
                display: flex;
                flex-direction: column;
                row-gap: 15px;

                .item-place {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;

                    .icon {
                        svg { display: block; }
                    }
                    .place-name {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }

                .item-title {
                    @include title-font;
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: 700;
                }

                .item-info {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    font-size: 18px;
                    line-height: 28px;

                    .info-author {
                        display: flex;
                        align-items: center;
                        column-gap: 10px;

                        .icon {
                            svg { display: block; }
                        }
                    }

                    .info-line  {
                        width: 2px;
                        height: 22px;
                        background: $primary-sand;
                    }
                }
                
                .item-desp {
                    font-size: 18px;
                    line-height: 28px;
                    white-space: pre-line;
                }
            }
        }
    }
    
    .recommend-section {
        box-sizing: border-box;
        width: 100%;
        padding: 60px 40px;
        background: $primary-beige;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;

        @include sm-desktop {
            padding: 60px 24px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;
        }

        .recommend-item-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                grid-template-columns: 1fr;
            }
        }

    }

}