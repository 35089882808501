@use "../../common/fontssetup" as *;
@import '../../common/variables';

.homepage-container {
    box-sizing: border-box;
    margin-top: -165px;
    padding-bottom: 165px;
    // padding: 50px;
    position: relative;
    
    // color: $primary-blue;
    font-size: $text-size-content;
    font-weight: $text-weight;
    line-height: $text-line-height;


    .slider-container {
        position: relative;
        width: 100%;

        .slick-prev {
            left: $page-section-left-right-padding;
            z-index: 10;
            border-radius: 300px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            width: 40px;
            height: 40px;
            top: 75vh;

            @include sm-desktop {
                top: auto;
                bottom: calc(85px - 12px);
                // left: 25%;
            }

            &::before {
                content: "\003C";
                font-family: inherit;
                padding-right: 1px;
            }
        }

        .slick-next {
            right: $page-section-left-right-padding;
            border-radius: 300px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            width: 40px;
            height: 40px;
            top: 75vh;

            
            @include sm-desktop {
                top: auto;
                bottom: calc(85px - 12px);
                // right: 25%;
            }

            &::before {
                content: "\003E";
                font-family: inherit;
                padding-right: 1px;
            }
        }
        
        .slick-dots {
            display: block;
            bottom: 50%;
            z-index: 100;
            left: $page-section-left-right-padding;
            width: auto;
            transform: rotate(90deg);

            
            @include sm-desktop {
                top: auto;
                bottom: 105px;
                transform: translateX(-50%);
                left: 50%;
            }

            li {
                button {
                    &::before {
                        font-size: 10px;
                        color: $primary-white;
                        opacity: 0.4;
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            color: #FED65D;
                            opacity: 1;
                        }
                    }

                }
            }
            
        }

        .news-item-container {
            width: 100%;
            height: 100vh;
            position: relative;

            @include sm-desktop {
                height: 750px;
            }
        }
        .bg-img-container {
            width: 100%;
            height: 100%;
        }
        .news-content {
            position: absolute;
            width: 100%;
            top: 65vh;
            display: grid;
            gap:30px;
            text-align: center;
            justify-content: center;
            color: $primary-white;

            
            @include sm-desktop {
                top: auto;
                bottom: 150px;
                gap:12px;
            }

            .info-top {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 36px;
                font-weight: 500;
                line-height: 44px;

                @include sm-desktop {
                    font-size: 24px;
                }
                

                .top-info-icon {
                    width: 24px;
                    padding-right: 10px;
                }
            }

            .desp {
                @include title-font;
                font-size: 48px;
                max-width: 900px;

                @include sm-desktop {
                    font-size: 32px;
                }
            }

            .btn-link {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;

                .btn-text {
                    min-width: 152px;
                    padding: 6px 20px;
                    border-radius: 300px;
                    background: $primary-sand;
                    cursor: pointer;
                    font-size: 20px;
                    line-height: 30px;
                    color: $primary-white;
                    text-decoration: none;
                }
            }
        }
    }

    .info-details-section {
        max-width: 1440px;
        margin: 0 auto;
        box-sizing: border-box;
        color: $primary-black;
        padding: 100px 60px;
        display: grid;
        text-align: center;
        
        @include sm-desktop {
            padding: 40px 24px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;

            @include sm-desktop {
                font-size: 32px;
            }
            
        }

        .line-break {
            padding: 36px;
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                width: 10%;
                height: 1px;
                background-color: $primary-sand;
            }
        }

        .section-desp {
            font-size: 18px;
            width: 75%;
            margin: 0 auto;

            @include sm-desktop {
                font-size: 16px;
                width: 100%;
            }
        }

        .circle-container {
            margin-top: 42px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            column-gap: 15px;

            @include sm-desktop {
                flex-direction: column;
                row-gap: 24px;
                padding: 0 24px;
                box-sizing: border-box;
            }

            .circle-item {
                box-sizing: border-box;
                width: calc((100% - 30px)/3);

                @include sm-desktop {
                    width: 100%;
                    max-width: 400px;
                }

                .item-detail {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 0;
                    padding: 50% 0;
                    border-radius: 50%;
                    border:1px solid $primary-sand;
                    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                }

                .item-icon {
                    width: 36px;

                    img {
                        width: 100%;
                        display: block;
                    }
                }

                .item-title {
                    font-size: 24px;
                    font-weight: 500;

                    @include sm-desktop {
                        font-size: 20px;
                    }
                }

                .item-content {
                    font-size: 18px;
                    padding: 0 18px;

                    @include sm-desktop {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .travel-idea-section {
        padding: $page-section-top-bottom-padding $page-section-left-right-padding;
        background-color: $primary-beige;

        @include sm-desktop {
            padding: 60px 24px;
        }

        .section-details-container{  
            max-width: 1440px;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 48px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;
            color: $primary-black;
            text-align: center;
        }
    }

    .lastest-news-slider-container {
        
        .slick-prev {
            left:auto;
            right: calc(40px + 40px + 30px);
            z-index: 10;
            border-radius: 300px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(5px);
            width: 40px;
            height: 40px;
            top: calc(100% - 60px);

            @include sm-desktop {
                top: calc(50% - 40px);
                right: auto;
                left: 40px;
            }

            &::before {
                content: "\003C";
                font-family: inherit;
                padding-right: 1px;
            }
        }

        .slick-next {
            right: 40px;
            border-radius: 300px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(5px);
            width: 40px;
            height: 40px;
            top: calc(100% - 60px);

            @include sm-desktop {
                top: calc(50% - 40px);
            }

            &::before {
                content: "\003E";
                font-family: inherit;
                padding-right: 1px;
            }
        }
        
        .slick-dots {
            display: block;
            bottom: 65%;
            z-index: 100;
            left: -10px;
            width: auto;
            transform: rotate(90deg);

            @include sm-desktop {
                display: none;
            }

            li {
                width: 10px;
                height: 10px;
                margin: 0px 5px;

                button {
                    &::before {
                        font-size: 10px;
                        color: $primary-white;
                        opacity: 0.3;
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            color: $primary-white;
                            opacity: 1;
                        }
                    }

                }
            }
            
        }

        .news-item-container {
            display: flex;
            position: relative;
            height: 70vh;

            @include sm-desktop {
                width: 100%;
                height: 100vh;
                flex-direction: column-reverse;
            }
    
            .news-content-section {
                display: flex;
                width: 40%;
                z-index: 11;
                background: rgba($color: $primary-sand, $alpha: 1);

                @include sm-desktop {
                    width: 100%;
                    height: 50%;
                }
                
                .news-content {
                    display: flex;
                    width: 100%;
                    margin: auto;
                    padding: 60px 40px;
                    flex-direction: column;
                    color: $primary-white;
                    align-items: flex-start;
    
                    .title {
                        @include title-font;
                        font-size: 48px;

                        @include sm-desktop {
                            font-size: 32px;
                        }
                    }
    
                    .news-line {
                        padding: 50px 0;

                        @include sm-desktop {
                            padding: 24px 0;
                        }
    
                        .line {
                            height: 2px;
                            width: 80px;
                            background-color: rgba(255, 255, 255, 0.50);
                        }
                    }
    
                    .sub-title {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 500;
                        padding-bottom: 15px;

                        @include sm-desktop {
                            font-size: 20px;
                        }
                    }
    
                    .desp {
                        font-size: 18px;
                        line-height: 28px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        margin-bottom: 15px;

                        @include sm-desktop {
                            font-size: 16px;
                        }
                    }
                    .rm-btn-link {
                        cursor: pointer;
                        display: flex;
                        padding: 6px 20px;
                        column-gap: 10px;
                        align-items: center;
                        border-radius: 100px;
                        color: $primary-white;
                        border: 1px solid $primary-white;

                        &:hover {
                            background-color: $primary-white;
                            color: $primary-black;
                            
                            svg {
                                path {
                                    fill: $primary-black;
                                }
                            }
                        }

                    }
    
                }
            }
    
            .img-content {
                width: 60%;
                overflow: hidden;
                // position: absolute;
                // top: 0;
                // left: 0;
                // z-index: 10;
                // width: 100%;

                @include sm-desktop {
                    width: 100%;
                    height: 50%;
                }

                img {
                    display: block;
                    // height: 100%;
                    width: 100%;
                    // float: right;

                    @include sm-desktop {
                        height: fit-content;
                        // float: none;
                    }
                }
            }
        }
    }

    .our-destinations-section {
        padding: 100px 0;
        background: $primary-beige;
        text-align: center;

        .section-title {
            @include title-font;
            font-size: 48px;

            @include sm-desktop {
                font-size: 32px;
            }
        }

        .section-item-title {
            display: flex;
            color: $primary-sand;
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            justify-content: center;
            padding-bottom: 24px;
            text-transform: uppercase;

            @include sm-desktop {
                font-size: 20px;
            }
        }

        .section-item {
            padding-top: 40px;
        }

        .destinations-item-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
    
            .destinations-item {
                width: 25%;
                @include sm-desktop {
                    width: 50%;
                }

                .item-details {
                    position: relative;
                    .item-img {
                        position: relative;
                        .grey-layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background:linear-gradient(180deg, rgba(86, 76, 74, 0.00) 61.44%, #564C4A 95.37%);
                            top: 0;
    
                            &:hover {
                                background: rgba(82, 69, 56, 0.87);
                            }
                        }
    
                        img {
                            width: 100%;
                            display: block;
                        }
                    }
                    .item-placename {
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                        color: $primary-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        row-gap: 15px;
                        text-transform: uppercase;
                        
                        .name {
                            font-size: 24px;
                            font-weight: 500;

                            @include sm-desktop {
                                font-size: 20px;
                            }
                        }
    
                        .rm-btn {
                            padding: 6px 20px;
                            border-radius: 300px;
                            background: $primary-sand;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                            display: none;
    
                            .btn-text {
                                font-size: 20px;
                                line-height: 30px;
                                white-space: nowrap;
                            }
                        }
                    }
    
                    &:hover {
                        .item-placename {
                            bottom: 50%;
                            transform: translate(-50% ,50%);
                        }
                        .rm-btn {
                            display: flex;
                        }
                    }
                }
    
            }
        }
    }

    .map-section {
        text-align: center;
        
        .section-details-container{  
            max-width: 1440px;
            box-sizing: border-box;
            margin: 0 auto;
            padding: 100px 40px;
            text-align: center;

            @include sm-desktop {
                padding: 60px 0;
            }
        }

        .section-title {
            @include title-font;
            font-size: 48px;
            padding: 0 40px;
            padding-bottom: 40px;
        }
    }

}