@import '../../common/variables';

.backtotop-container {
    position: relative;
    bottom: 40px;
    right: 30px;
    cursor: pointer;
    z-index: 90;

    .backtotop-btn {
        display: block;
        margin-right: auto;
        position: relative;
        width: 50px;
        left: calc(100% - 80px)
    }
}