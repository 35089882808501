@use "../../common/fontssetup" as *;
@import '../../common/variables';

.contactuspage-container {    

    
    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .page-content {
        box-sizing: border-box;
        margin-top: -165px;
        padding: 100px 40px;
        padding-top: 165px;
        position: relative;
        
        // color: $primary-blue;
        font-size: $text-size-content;
        font-weight: $text-weight;
        line-height: $text-line-height;

        @include sm-desktop {
            padding-left: 0;
            padding-right: 0;
        }

        .info-section {
            box-sizing: border-box;
            margin: 0 auto;
            max-width: 1440px;
            padding: 60px 40px;
            padding-bottom: 0;

            .info-item {
                display: flex;
                align-items: center;
                padding-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                }
                
                .info-icon {
                    padding-right: 15px;
                    img {
                        display: block;
                        width: 24px;
                    }
                }
            }
        }
    }


}