@import '../../common/variables';

.travel-idea-tab-section {
    width: 100%;
    padding-bottom: 90px;

    .tab-item-container {
        display: flex;
        justify-content: center;

        .tab-item {
            padding: 10px 40px;
            border: 1px solid $primary-sand;
            background: $primary-white;
            font-size: 20px;
            line-height: 30px;
            cursor: pointer;

            &.active {
                background-color: $primary-dark-brown;
                color: $primary-white;
            }

        }

        @include sm-desktop {
            display: none;
        }
    }
    .tab-item-container-mb {
        display: none;
        width: 100%;

        @include sm-desktop {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .item-selection {
            width: 100%;
            padding: 10px 20px;
            max-width: 400px;
            font-size: 16px;
            border-color: $primary-sand;
            
            -moz-appearance:none; /* Firefox */
            -webkit-appearance:none; /* Safari and Chrome */
            appearance:none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z' fill='black'/%3E%3C/svg%3E");
            background-repeat:no-repeat;
            background-position:calc(100% - 20px);
            background-size: 24px;

            &:focus-visible {
                outline: none;
            }

            option:checked  { 
                background-color: $primary-sand2;
                color: $primary-white;
            }
            option:hover,
            option:focus,
            option:active,
            option:checked   { 
                background-color: $primary-sand2;
                color: $primary-white;
            }
        }
        
    }

    .tab-details-container {
        display: none;

        &.active {
            display: block;

            &.no-slider {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: auto auto;

                @include sm-desktop {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                }
            }
        }


        .slick-prev {
            left: 40%;
            z-index: 100;
            border-radius: 300px;
            border: 1px solid rgba(86, 76, 74, 0.30);
            width: 40px;
            height: 40px;
            top: auto;
            bottom: -85px; //25+40+20

            &::before {
                content: "\003C";
                font-family: inherit;
                padding-right: 1px;
                color: $primary-dark-brown;
            }

            @include sm-desktop {
                left: 0%;
            }
        }

        .slick-next {
            right: 40%;
            z-index: 10;
            border-radius: 300px;
            border: 1px solid rgba(86, 76, 74, 0.30);
            width: 40px;
            height: 40px;
            top: auto;
            bottom: -85px;

            &::before {
                content: "\003E";
                font-family: inherit;
                padding-right: 1px;
                color: $primary-dark-brown;
            }

            @include sm-desktop {
                right: 0%;
            }
        }
        
        
        .slick-dots {
            display: block;
            bottom: -60px;

            li {
                button {
                    &::before {
                        font-size: 10px;
                        color: #32323233;
                        opacity: 0.2;
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            color: $primary-sand;
                            opacity: 1;
                        }
                    }

                }
            }
            
        }

        @include sm-desktop {
            .tab-content-slider-item {
                display: flex;
                justify-content: center;
            }

            .travel-item {
                max-width: 600px;
            }
        }
    }
}