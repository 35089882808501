@use "../../common/fontssetup" as *;
@import '../../common/variables';

.contactus-container {
    position: sticky;
    display: flex;
    top: 80vh;
    z-index: 90;

    @include sm-desktop {
        width: 100%;
        // top: calc(168px + 100vh);
        top: 100vh;
    }

    .contactus-section {
        background-color: rgba(188, 147, 87, 0.80);
        padding: 10px 32px;
        color: $primary-white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 100px;
        border: 1px solid var(--White, #FFF);
        background: rgba(188, 147, 87, 0.90);

        // margin: auto;
        transform: rotate(90deg);
        right: 0;
        bottom: 168px;
        position: absolute;

        @include sm-desktop {
            border-radius: 0;
            transform: none;
            width: 100%;
            box-sizing: border-box;
            border: 0;
            bottom: 0;
        }

        svg {
            display: block;
        }
        .cls-1 {
            fill: none;
            stroke: #fff;
            stroke-linejoin: round;
            stroke-width: 1.3px;
        }

        .section-title {
            display: none;

            @include sm-desktop {
                display: block;
            }
        }

        .icon-section {
            display: flex;
            align-items: center;
            column-gap: 32px;

            .line {
                width: 1px;
                height: 40px;
                background: $primary-white;
                opacity: 0.2;
            }

            .inbox-section {
                cursor: pointer;
                @include sm-desktop {
                    display: none;
                }
                .inbox-icon {
                    transform: rotate(-90deg);
                }
            }

            .phone-section {
                display: none;

                @include sm-desktop {
                    display: block;
                }
            }

            .whatsapp-section {

                .whatsapp-icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}