@use "../../common/fontssetup" as *;
@import '../../common/variables';

.aboutus-positive-impact-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 24px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    
    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        margin: 0 auto;

        .content-details-section {
            // width: 70%;
            width: 100%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;
            padding-bottom: 100px;
            font-size: 18px;
            line-height: 28px;
    
            &.beige-bg {
                background: $primary-beige;
            }
            
    
            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 100px;
            }
    
            .content-child-item {
                padding-bottom: 60px;
    
                &:last-child {
                    padding-bottom: 0;
                }
    
                .item_details {
                    img {
                        border-radius: 50px;
                    }
                }
            }
    
            .details-sub-section {
                padding: 60px 0;
                
                &:first-child {
                    padding-top: 0;
                }
            }
    
            .title {
                @include title-font;
                font-size: 48px;
    
            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }
    
            h3{
                padding-bottom: 24px;
                font-size: 24px;
                font-weight: 500;
                margin: 0;
            }
            .desp {
                padding-bottom: 24px;
                font-size: 18px;
                line-height: 28px;
    
                strong {
                    font-weight: 700;
                }
            }
    
            .weather-section {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px 24px;
    
                @include sm-desktop {
                    grid-template-columns: 1fr;
                }
    
    
                .weather-box {
                    background: $primary-white;
                    border-radius: 20px;
                    padding: 20px;
    
                    .box-title {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        padding-bottom: 24px;
                    }
                    .box-desp {
                        display: flex;
                        justify-content: space-between;
    
                        .separate-line {
                            width: 1px;
                            background: $primary-black;
                            margin: 0 10px;
                        }
                    }
                    .part-desp {
                        font-size: 18px;
                        line-height: 28px;
                    }
    
                    .part-title {
                        font-weight: 700;
                        padding-right: 40px;
                    }
    
                    &.text-text {
                        .box-desp {
                            display: grid;
                            grid-template-columns: auto 1fr;
                            grid-template-rows: 1fr 1fr;
                        }
                    }
                }
                
                &.have-outline {
                    .weather-box {
                        border-radius: 20px;
                        border: 1px solid $primary-sand2;
                    }
                }
            }
    
            .moment-item {
                .moment-title {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 24px;
    
                    .title-item {
                        position: relative;
                        font-size: 18px;
                        line-height: 28px;
                        padding-bottom: 2px;
                        font-weight: bold;
                    }
    
                    .text-underline {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background: $primary-sand;
                    }
                }
                .moment-desp {
                    font-size: 18px;
                    line-height: 28px;
                    padding-bottom: 24px;
                }
                .momment-img-section {
                    width: 100%;
    
                    .gallery{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 60px;
                        row-gap: 24px;
                        padding-bottom: 24px;

                        @include sm-desktop {
                            grid-template-columns: 1fr;
                        }
                    }
    
                    .gallery-item {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
    
                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50px;
                        }
                    }
    
                }
            }
    
            .map-btn {
                &-container {
                    display: flex;
                }
                padding: 6px 20px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                border-radius: 300px;
                border: 1px solid $primary-sand;
                background: $primary-white;
                text-decoration: none;
    
                .btn-text {
                    color: $primary-black;
                    font-size: 20px;
                    line-height: 30px;
                }
            }
    
            .icon-title {
                display: flex;
                align-items: center;
                column-gap: 16px;
                padding-bottom: 24px;
    
                .icon {
                    width: 25px;
                    height: 25px;
                }
    
                .title-text {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
    
            .gallery{
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 60px;
                row-gap: 24px;
                // padding-bottom: 24px;
                

                @include sm-desktop {
                    grid-template-columns: 1fr;
                }
            }
    
            .gallery-item {
                width: 100%;
                overflow: hidden;
                margin: 0;
    
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 50px;
                }
            }
        }
    }
    
    .recommend-section {
        box-sizing: border-box;
        width: 100%;
        padding: 60px 40px;
        background: $primary-beige;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;

        .section-title {
            @include title-font;
            font-size: 48px;
        }

        .recommend-item-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                grid-template-columns: 1fr;
            }
        }

    }

}