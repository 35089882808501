@use "../../common/fontssetup" as *;
@import '../../common/variables';

.info-details-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }


    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        @include sm-desktop {
            flex-direction: column;
        }

        .page-info-section {
            width:30%;
            box-sizing: border-box;
            padding: 60px 40px;

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px;
                padding-bottom: 0;
            }
            
            .info-box-container {
                background: $primary-beige;
                padding: 40px;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                

                @include sm-desktop {
                    width: 80%;
                    max-width: 300px;
                }
            }

            .profile-img-item {
                width: 100%;
                height: auto;
                border-radius: 24px;
                overflow: hidden;

                .profile-img {
                    display: block;
                    width: 100%;
                }
            }
            .profile-name {
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
            }
            .line {
                width: 100%;
                height: 2px;
                background-color: $primary-sand;
            }
            .page-date {
                font-size: 18px;
                line-height: 28px;
            }

        }

        .content-details-section {
            width: 70%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;
            padding-bottom: 100px;

            &.beige-bg {
                background: $primary-beige;
            }
            &.full-width {
                width: 100%;
            }
            

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 100px;
            }

            .content-desp {
                font-size: 18px;
                line-height: 28px;

                p {
                    margin: 0;
                }

                strong {
                    font-weight: 700;
                }

                a {
                    color: $primary-sand;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    align-items: center;
                    column-gap: 10px;

                    &::before {
                        content: url("data:image/svg+xml,    %3Csvg%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cg%20id%3D'basic%2Flink'%3E%3Cpath%20id%3D'Shape'%20d%3D'M12.7482%203L20.3019%203.00051L20.4022%203.01443L20.501%203.04203L20.5613%203.0676C20.6405%203.10243%2020.7148%203.15311%2020.78%203.21831L20.8207%203.26235L20.8812%203.34383L20.9184%203.40885L20.9571%203.49917L20.9763%203.5633L20.9899%203.62724L20.9992%203.72043L20.9997%2011.2539C20.9997%2011.6681%2020.6639%2012.0039%2020.2497%2012.0039C19.87%2012.0039%2019.5562%2011.7217%2019.5066%2011.3557L19.4997%2011.2539L19.4993%205.558L4.27966%2020.7833C4.01342%2021.0496%203.59676%2021.0738%203.30313%2020.856L3.219%2020.7834C2.9527%2020.5172%202.92845%2020.1005%203.14627%2019.8069L3.21888%2019.7227L18.4383%204.499L12.7482%204.5C12.3685%204.5%2012.0547%204.21785%2012.005%203.85177L11.9982%203.75C11.9982%203.3703%2012.2803%203.05651%2012.6464%203.00685L12.7482%203Z'%20fill%3D'%23BC9357'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                        width: 24px;
                        height: 24px;
                    }
                }

                
                .gallery{
                    margin: 60px 0;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 60px;
                    row-gap: 24px;

                    @include sm-desktop {
                        grid-template-columns: 1fr;
                    }
                }

                .gallery-item {
                    width: 100%;
                    overflow: hidden;
                    margin: 0;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 50px;
                    }
                }

            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            .sub-title {
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
            }
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            .info-child-list {
                padding: 120px 0;
                display: flex;
                flex-direction: column;
                row-gap: 60px;

                .btn-link {
                    display: flex;
                    .btn {
                        padding: 6px 20px;
                        border-radius: 300px;
                        background-color: $primary-sand;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;

                        .btn-name {
                            color: $primary-white;
                            font-size: 20px;
                            line-height: 30px;

                        }

                        .btn-icon {
                            svg { display: block; }
                        }
                    }
                }

                .icon-title {
                    display: flex;
                    align-items: center;
                    column-gap: 16px;
                    padding-bottom: 24px;
    
                    .icon {
                        width: 25px;
                        height: 25px;
                    }
    
                    .title-text {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                .info-table-section {
                    display: grid;
                    grid-template-columns: repeat(2,auto);
                    gap: 24px;

                    .table-item {
                        font-size: 18px;
                        line-height: 28px;
                        white-space: pre-line;

                        &.all-width {
                            grid-column: 1/3;
                        }

                        &.item-title {
                            font-weight: 700;
                            display: flex;

                            .title-text{
                                position: relative;
                                &::before {
                                    content: '';
                                    width: 100%;
                                    height: 1px;
                                    background-color: $primary-sand2;
                                    position: absolute;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .recommend-section {
        box-sizing: border-box;
        width: 100%;
        padding: 60px 40px;
        background: $primary-beige;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;

        @include sm-desktop {
            padding: 60px 24px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;
        }

        .recommend-item-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                grid-template-columns: 1fr;
            }
        }

    }

}