@use "../../common/fontssetup" as *;
@import '../../common/variables';

.destinations-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        margin: 0 auto;

        .recommend-section {
            width:30%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;

            @include sm-desktop {
                display: none;
            }

            .page-list {
                font-family: "Georgia";
                list-style-type: none;
                position: sticky;
                top: 15%;
                    
                .more-btn {
                    order: 5;
                    margin: 20px 0;
                    padding: 6px 20px;
                    border-radius: 300px;
                    background: $primary-sand2;
                    color: $primary-white;
                    font-size: 20px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    cursor: pointer;

                    svg {display: block;}

                    &.hide {
                        display: none;
                    }
                }

                .page-list-item {
                    box-sizing: border-box;
                    padding-top: 20px;
                    order: 1;

                    &.section-list {
                        display: flex;
                        flex-direction: column;
                    }
                    

                    &:first-child {
                        padding-top: 0;
                    }

                    &.item-hidden {
                        display: none;
                    }

                    &.active {
                        display: block;
                        order: 0;
                        // color: $primary-sand;
                    }

                    .title {
                        color: $primary-sand;
                        font-size: 36px;
                        font-weight: 500;
                        line-height: 44px;
                        border-bottom: 0px;
                        white-space: nowrap;
                    }

                    .sub-title {
                        display: block;
                        width: 100%;
                        color: $primary-black;
                        padding-bottom: 20px;
                        font-size: 24px;
                        line-height: 32px;
                        text-decoration: none;
                        border-bottom: 2px solid $primary-sand;

                        &.active {
                            color: $primary-sand;
                        }
                    }

                }

                .sub-page-list {
                    list-style-type: none;
                    padding: 0;
                    display: none;

                    &.active {
                        display: block;
                    }

                    .page-list-item {
                        box-sizing: border-box;
                        padding: 20px 30px;
                        border-bottom: 1px dashed $primary-sand2;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 28px;

                        a {
                            text-decoration: none;
                            color: inherit;

                            &:hover {
                                color: $primary-sand;
                                text-decoration: underline;
                            }
                        }
                    }

                }
            }
        }

        .content-details-section {
            width: 70%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;
            padding-bottom: 100px;
            font-size: 18px;
            line-height: 28px;

            &.beige-bg {
                background: $primary-beige;
            }
            

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 100px 24px;
            }

            .content-child-item {
                padding-bottom: 60px;

                &:last-child {
                    padding-bottom: 0;
                }

                .item_details {
                    img {
                        border-radius: 50px;
                    }
                }
            }

            .details-sub-section {
                padding: 60px 0;
                
                &:first-child {
                    padding-top: 0;
                }
            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            h3{
                padding-bottom: 24px;
                font-size: 24px;
                font-weight: 500;
                margin: 0;
            }
            .desp {
                padding-bottom: 24px;
                font-size: 18px;
                line-height: 28px;

                strong {
                    font-weight: 700;
                }
            }

            .weather-section {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px 24px;

                @include sm-desktop {
                    grid-template-columns: 1fr;
                }


                .weather-box {
                    background: $primary-white;
                    border-radius: 20px;
                    padding: 20px;
                    overflow-x: auto;

                    .box-title {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        padding-bottom: 24px;
                    }
                    .box-desp {
                        display: flex;
                        justify-content: space-between;
                        min-width: 350px;

                        .separate-line {
                            width: 1px;
                            background: $primary-black;
                            margin: 0 10px;
                        }
                    }
                    .part-desp {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    .part-title {
                        font-weight: 700;
                        padding-right: 40px;
                    }

                    &.text-text {
                        .box-desp {
                            display: grid;
                            grid-template-columns: auto 1fr;
                            grid-template-rows: 1fr 1fr;
                        }
                    }
                }
                
                &.have-outline {
                    .weather-box {
                        border-radius: 20px;
                        border: 1px solid $primary-sand2;
                    }
                }
            }

            .moment-item {
                .moment-title {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 24px;

                    .title-item {
                        position: relative;
                        font-size: 18px;
                        line-height: 28px;
                        padding-bottom: 2px;
                        font-weight: bold;
                    }

                    .text-underline {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background: $primary-sand;
                    }
                }
                .moment-desp {
                    font-size: 18px;
                    line-height: 28px;
                    padding-bottom: 24px;
                }
                .momment-img-section {
                    width: 100%;

                    .gallery{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 60px;
                        row-gap: 24px;
                        padding-bottom: 24px;

                        @include sm-desktop {
                            grid-template-columns: 1fr;
                        }
                    }

                    .gallery-item {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;

                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50px;
                        }
                    }

                }
            }

            .map-btn {
                &-container {
                    display: flex;
                }
                padding: 6px 20px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                border-radius: 300px;
                border: 1px solid $primary-sand;
                background: $primary-white;
                text-decoration: none;

                .btn-text {
                    color: $primary-black;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .icon-title {
                display: flex;
                align-items: center;
                column-gap: 16px;
                padding-bottom: 24px;

                .icon {
                    width: 25px;
                    height: 25px;
                }

                .title-text {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .gallery{
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 60px;
                row-gap: 24px;
                // padding-bottom: 24px;

                @include sm-desktop {
                    grid-template-columns: 1fr;
                }
            }

            .gallery-item {
                width: 100%;
                overflow: hidden;
                margin: 0;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 50px;
                }
            }
        }
    }

}