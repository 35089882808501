@use "../../common/fontssetup" as *;
@import '../../common/variables';


.item-link {
    text-decoration: none;
}
.travel-item {
    border-radius: 50px;
    background: $primary-white;
    margin: 20px;
    text-decoration: none;

    @include sm-desktop {
        margin: 20px 0 ;
    }

    .item-image {
        width: 100%;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }



    .item-content {
        box-sizing: border-box;
        padding: 30px 40px;
        color: $primary-black;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        @include sm-desktop {
            padding: 20px 30px;
        }

        .item-title {
            height: 84px;
            overflow: hidden;
            @include title-font;
            font-size: 24px;
            line-height: 28px;
            text-decoration: none;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .item-place {
            display: flex;
            align-items: center;
            column-gap: 10px;
            height: 56px;
            overflow: hidden;

            .place-icon {
                display: block;
                width: 24px;

                path {
                    fill: $primary-black;
                }
            }

            .place-text {
                width: calc(100% - 34px);
                @include title-font;
                font-size: 18px;
                line-height: 28px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

            }
        }

        .item-desp {
            font-size: 18px;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .item-night {
            @include title-font;
            font-size: 21px;
            line-height: 28px;
            display: flex;
            padding: 5px 20px;
            align-items: flex-start;
            background: $primary-sand2;
        }

        .item-price {
            font-size: 18px;
            line-height: 28px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: $primary-sand;

        }
    }

    &:hover {

        background-color: $primary-dark-brown;

        .item-content {
            color: $primary-white;

            .item-place {

                .place-icon {
                    path {
                        fill: $primary-white;
                    }
                }

            }

            .item-night {
                color: $primary-black;
            }
        }
            
    }
}