@import '../../common/variables';

.footer-container {
    position: relative;
    background-color: $primary-brown;
    color: $primary-white;
    padding: 36px 40px;
    font-size: $text-size-footer;

    .top-section {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;

        @include sm-desktop {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 30px;
        }

        .info-section {
            display: flex;
            
            @include sm-desktop {
                flex-direction: column;
                row-gap: 10px;
            }
        }
        
        .info-container {
            display: flex;
            align-items: center;
            margin-right: 30px;
            column-gap: 10px;

            &:last-child {
                margin-right: 0;
            }

            .info-icon {
                width: 24px;
            }
        }
    }

    .footer-line {
        padding: 24px 0;
        opacity: 0.5;
    }

    .page-section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        

        @include sm-desktop {
            flex-direction: column;
        }

        .page-container {
            @include sm-desktop {
                width: 100%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .page-title {
                color: $primary-sand2;
                font-size: $text-size-footer-title;
                padding-bottom: 20px;

                @include sm-desktop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 0;
                }

                svg {
                    display: none;

                    @include sm-desktop {
                        display: block;
                    }
                }

                &.active {
                    svg {

                        transform: rotate(180deg);
                    }
                }
            }

            .page-item-container {

                @include sm-desktop {
                    display: none;

                    &.active {
                        display: block;
                    }

                }
                .page-item-link {
                    color: $primary-white;
                    font-size: 18px;
                    line-height: 28px;
                    text-decoration: none;

                    @include sm-desktop {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }

    .bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include sm-desktop {
            padding-top: 24px;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 24px;
        }

        .social-section {
            display: flex;
            align-items: center;
            column-gap: 24px;

            .media-icon {
                padding: 8px;
                border-radius: 254.545px;
                background: $primary-sand;
    
                svg { display: block; }
            }

            .text-item {
                font-size: 20px;
                line-height: 30px;
                text-transform: capitalize;

                @include sm-desktop {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        .copy-text {
            font-size: 14px;
            line-height: 28px;
            opacity: 0.7;

            @include sm-desktop {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

}