@use "../../common/fontssetup" as *;
@import '../../common/variables';

.itineraries-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;
            
            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .mb-page-list-btn {
        display: none;

        @include sm-desktop {
            box-sizing: border-box;
            background: $primary-beige;
            position: fixed;
            z-index: 100;
            bottom: 70px;
            width: calc(100% - 40px);
            margin: 0 20px;
            padding: 10px 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 50px;
            background: var(--Beige, #F4F0E8);
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        .page-list-section {
            width:30%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;

            @include sm-desktop {
                box-sizing: border-box;
                position: fixed;
                top: 100%;
                left: 0;
                transition: all 1s ease;
                background: $primary-beige;
                width: 100%;
                height: 100vh;
                z-index: 1000;
                padding: 40px;
                padding-bottom: 20px;
                overflow: auto;

                &.active {
                    top: 0;
                    transition: all 1s ease;
                }
            }

            .close-section {
                text-align: right;
                margin-bottom: 30px;
            }

            .sub-page-section {

                @include sm-desktop {
                    box-sizing: border-box;
                    // position: fixed;
                    // top: 0;
                    // left: 100%;
                    // transition: all 1s ease;
                    // background: $primary-beige;
                    // width: 100%;
                    // height: 100vh;
                    // z-index: 11;
                    // padding: 40px;
                    padding-bottom: 20px;
                    display: none;

                    &.active {
                        display: block;
                        // left: 0;
                        // transition: all 1s ease;
                    }
                }

                .sub-page-title-section {
                    display: none;

                    @include sm-desktop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 30px;
                    }

                    .title-name {
                        font-size: 20px;
                        line-height: 32px;
                        font-weight: 500;
                    }
                }
            }

            .page-list {
                font-family: "Georgia";
                list-style-type: none;
                // position: sticky;
                // top: 15%;

                @include sm-desktop {
                    // position: relative;
                    // top: 0;
                    // padding: 0;
                    font-family: "Karla";
                    margin: 0;
                }

                .sub-page-list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                }

                .more-btn {
                    margin: 20px 0;
                    padding: 6px 20px;
                    border-radius: 300px;
                    background: $primary-sand2;
                    color: $primary-white;
                    font-size: 20px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    cursor: pointer;

                    svg {display: block;}
                }

                .page-list-item {
                    box-sizing: border-box;
                    padding-bottom: 40px;

                    &.active {
                        color: $primary-sand;
                    }

                    .title {
                        color: $primary-sand;
                        font-size: 36px;
                        font-weight: 500;
                        line-height: 44px;
                        border-bottom: 0px;
                        white-space: nowrap;

                        @include sm-desktop {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: $primary-black;
                            font-size: 24px;
                            line-height: normal;

                            &.active {
                                color: $primary-sand;
                                padding-bottom: 20px;

                                .icon {
                                    svg { transform: rotate(90deg);}
                                }
                            }
                        }

                        .icon {
                            display: none;

                            @include sm-desktop {
                                display: block;
                                svg {
                                    display: block;
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }


                    .page-item {
                        box-sizing: border-box;
                        padding: 20px 0;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        border-bottom: 2px solid $primary-sand;
                        &.item-hidden {
                            display: none;
                        }

                        @include sm-desktop {
                            font-size: 20px;
                            line-height: 32px;
                            font-weight: 500;
                        }


                        .page-link {
                            text-decoration: none;
                            color: $primary-black;
                            
                            &.active {
                                color: $primary-sand;
                            }
                            
                            &:hover {
                                color: $primary-sand;
                                text-decoration: underline;
                            }
                        }
                    }

                }
            }
        }

        .content-details-section {
            width: 70%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;
            padding-bottom: 100px;

            &.beige-bg {
                background: $primary-beige;
            }
            

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 100px;
            }

            .content-desp {
                font-size: 18px;
                line-height: 28px;

                p {
                    margin: 0;
                }

                strong {
                    font-weight: 700;
                }

            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            .travel-item-list {
                padding-top: 40px;
                width: 100%;
                display: block;

                &.no-slider {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    grid-template-rows: auto;

                    @include sm-desktop {
                        grid-template-columns: 1fr;
                    }
                }

                .travel-item {
                    border: 1px solid $primary-sand;
                }

                .slick-prev {
                    left: 40%;
                    z-index: 100;
                    border-radius: 300px;
                    border: 1px solid rgba(86, 76, 74, 0.30);
                    width: 40px;
                    height: 40px;
                    top: auto;
                    bottom: -85px; //25+40+20

                    &::before {
                        content: "\003C";
                        font-family: inherit;
                        padding-right: 1px;
                        color: $primary-dark-brown;
                    }

                    @include sm-desktop {
                        left: 0%;
                    }
                }

                .slick-next {
                    right: 40%;
                    z-index: 10;
                    border-radius: 300px;
                    border: 1px solid rgba(86, 76, 74, 0.30);
                    width: 40px;
                    height: 40px;
                    top: auto;
                    bottom: -85px;

                    &::before {
                        content: "\003E";
                        font-family: inherit;
                        padding-right: 1px;
                        color: $primary-dark-brown;
                    }

                    @include sm-desktop {
                        right: 0%;
                    }
                }


                .slick-dots {
                    display: block;
                    bottom: -60px;

                    li {
                        button {
                            &::before {
                                font-size: 10px;
                                color: #32323233;
                                opacity: 0.2;
                            }
                        }

                        &.slick-active {
                            button {
                                &::before {
                                    color: $primary-sand;
                                    opacity: 1;
                                }
                            }

                        }
                    }

                }

                
            }

        }
    }

    .recommend-section {
        box-sizing: border-box;
        width: 100%;
        padding: 60px 40px;
        background: $primary-beige;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;

        @include sm-desktop {
            padding: 60px 24px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;
        }

        .recommend-item-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                grid-template-columns: 1fr;
            }
        }

    }

}