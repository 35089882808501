

@font-face {
    font-family: "MinionPro";
    src: local("MinionPro"),
     url("../assets/fonts/MinionPro-Regular.otf") format("opentype");
     font-weight: 400;
}

// @font-face {
//     font-family: 'cwTeXYen';
//     font-style: normal;
//     font-weight: 500;
//     src: url(//fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.eot);
//     src: url(//fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.eot?#iefix) format('embedded-opentype'),
//     url(//fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.woff2) format('woff2'),
//     url(//fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.woff) format('woff'),
//     url(//fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.ttf) format('truetype');
//     }


@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');