@use "../../common/fontssetup" as *;
@import '../../common/variables';

.confirm-msg {
    display: none;

    &.active {
        display: flex;
    }
    box-sizing: border-box;
    padding: 40px;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;

    .close-btn {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor: pointer;
    }

    .msg-img {
        display: block;
        width: 100%;
        max-width: 1024px;
        margin: auto;
        max-height: 80vh;
    }
}

.contactus-form-container {
    position: relative;
    padding: 150px 72px;
    padding-bottom: 0;
    max-width: 1440px;
    margin: 0 auto;

    @include sm-desktop {
        padding-left: 20px;
        padding-right: 20px;
    }

    .contactus-form-container {
        padding: 40px;
        border-radius: 50px;
        border: 1px solid $primary-sand;
        background-color: $primary-white;
        position: relative;

        @include sm-desktop {
            padding: 40px 20px;
        }

        .form-icon {
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -75%);
        }

        .form-title {
            @include title-font;
            font-size: 48px;
            text-align: center;
            padding-bottom: 36px;

            @include sm-desktop {
                font-size: 32px;
            }
        }
            
        .item-left-section {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
        }
        .item-right-section {
            display: flex;
            flex-direction: column;
            row-gap: 24px;

            @include sm-desktop {
                row-gap: 30px;
            }
        }

        .wp-form {
            display: none !important;

            .wpforms-hidden {
                display: none;
            }

            #wpforms-1644 {
                margin: 0;
            }

            .input-item {
                display: flex;
                flex-direction: column;

                label {
                    color: $primary-black;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                }

                input {
                    width: 100%;
                    border: 0;
                    border-bottom: 1px solid $primary-black;
                    color: $primary-black;
                    font-size: 14px;
                    line-height: 28px;
                    transition: none;
                    border-radius: 0;
                    padding: 0;
                    height: auto;

                    &:focus-visible {
                        outline: 0;
                        border: 0;
                        border-bottom: 1px solid $primary-sand2;
                        box-shadow: none;
                    }

                    @include sm-desktop {
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &::placeholder {
                        color: $primary-dark-grey;
                        font-size: 14px;
                        line-height: 28px;
                      }
                      
                      &::-ms-input-placeholder { 
                        font-size: 14px;
                        line-height: 28px;
                      }
                    
                }

                .wpforms-field-layout {
                    display: flex;
                    align-items: stretch;
                    column-gap: 36px;
                }
            }
            

            .wpforms-field-row {
                p {
                    margin-bottom: 0;
                }
            }

            
            select {
                width: 100%;
                height: 28px;
                font-size: 14px;
                line-height: 28px;
                padding: 0;
                border: 0;
                border-bottom: 1px solid $primary-black;
                box-shadow: 0;
                border-radius: 0;

                &:focus-visible {
                    outline: 0;
                    border-bottom: 1px solid $primary-sand2;
                    box-shadow: none;
                }

                &.placeholder {
                    color: $primary-dark-grey;
                }

                option {
                    color: $primary-black;
                }
            }
        }

        .contactus-form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 60px;
            grid-template-rows: auto;
            row-gap: 36px;
            grid-template-areas: 
            "item-left item-right"
            "item-des item-des"
            "action action";

            @include sm-desktop {
                row-gap: 30px;
                grid-template-columns: 1fr;
                grid-template-areas: 
                "item-left"
                "item-right"
                "item-des"
                "action";
            }

            .error-msg {
                color: $error-red;
                font-size: 14px;
                line-height: 28px;
            }

            select {
                width: 100%;
                height: 28px;
                font-size: 14px;
                line-height: 28px;
                border: 0;
                border-bottom: 1px solid $primary-black;

                &:focus-visible {
                    outline: 0;
                    border-bottom: 1px solid $primary-sand2;
                }

                &.placeholder {
                    color: $primary-dark-grey;
                }

                option {
                    color: $primary-black;
                }
            }


            .input-item {
                width: 100%;

                .item-name {
                    color: $primary-black;
                    font-size: 18px;
                    line-height: 28px;

                    @include sm-desktop {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .item-input {
                    width: 100%;
                    border: 0;
                    border-bottom: 1px solid $primary-black;
                    font-size: 14px;
                    line-height: 28px;

                    &:focus-visible {
                        outline: 0;
                        border-bottom: 1px solid $primary-sand2;
                    }

                    @include sm-desktop {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                &.enquiry {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 6px;
    
                    .enquiry-input {
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        padding: 10px;
                        display: block;
                        border: 1px solid $primary-black;
                        border-radius: 10px;
                        font-size: 14px;
                        line-height: 28px;
                        resize: none;

                        &:focus-visible {
                            outline: 0;
                            border: 1px solid $primary-sand2;
                        }
                    }
                }
            }

            

            .contact-items-section {
                display: flex;
                align-items: stretch;
                column-gap: 36px;
                
                @include sm-desktop {
                    flex-direction: column;
                    row-gap: 24px;
                }
            }

            .trvel-period-section {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                column-gap: 36px;

                
                @include sm-desktop {
                    grid-template-columns: auto;
                }

                .section-title {
                    grid-column: 1/3;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 400;

                    @include sm-desktop {
                        grid-column: auto;
                    }
                }

                .item-name {
                    font-size: 14px;
                }

                .travel_month {
                    @include sm-desktop {
                        margin-bottom: 36px;
                    }
                }
            }

            .num-people-section {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                column-gap: 36px;
                align-items: flex-start;

                @include sm-desktop {
                    grid-template-columns: auto;
                }

                .input-item {
                    display: grid;
                    grid-template-columns: repeat(2, auto);

                    .error-msg {
                        grid-column: 1/3;
                    }
                }

                .section-title {
                    grid-column: 1/3;
                    font-size: 18px;
                    line-height: 28px;
                    
                    @include sm-desktop {
                        grid-column: auto;
                    }

                }

                // .item-name {
                //     font-size: 14px;
                // }

                .item-remark {
                    font-size: 14px;
                    line-height: 28px;
                    color: $primary-dark-grey;
                    grid-column: 1/3;
                }

                .item-input-section {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include sm-desktop {
                        justify-content: flex-end;
                    }

                    .num-input {
                        width: auto;
                        border: 0;
                        background: none;
                        font-size: 14px;
                        line-height: 28px;
                        text-align: center;
    
                        &:focus-visible {
                            outline: 0;
                            border-bottom: 1px solid $primary-sand2;
                        }
                    }

                    .num-control-icon {
                        border: 0;
                        background: none;
                        cursor: pointer;
                    }
                }
            }

            .item-des-section {
                grid-area: item-des;

                .error-msg {
                    text-align: center;
                }

                .section-title {
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;

                    @include sm-desktop {
                        font-size: 20px;
                    }
                }
                .categories-list {
                    padding-top: 24px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 24px;
                    align-items: center;

                    .item-categories {
                        font-size: 20px;
                        line-height: 30px;
                        color: $primary-sand;
                        padding: 4px 40px;
                        border-bottom: 1px solid $primary-sand;
                    }
                    .item-des-list {
                        display: flex;
                        flex-wrap: wrap;
                        column-gap: 24px;
                        row-gap: 12px;
                        align-items: center;
                        justify-content: center;

                        @include sm-desktop {
                            display: grid;
                            grid-template-columns: repeat(2, auto);
                        }

                        .des-item {
                            box-sizing: border-box;
                            min-width: 152px;
                            text-align: center;
                            padding: 6px 20px;
                            border-radius: 300px;
                            border: 1px solid $primary-sand;
                            cursor: pointer;
                            font-size: 20px;
                            line-height: 30px;

                            @include sm-desktop {
                                min-width: 50%;
                                overflow: hidden;
                                font-size: 16px;
                                line-height: 24px;
                            }

                            &:hover {
                                background: $primary-sand;
                                color: $primary-white;
                            }

                            &.active {
                                background: $primary-sand;
                                color: $primary-white;

                            }
                        }
                    }
                }
            }

            .action-btn {
                grid-area: action;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 6px;
                align-self: stretch;

                .sumbit-btn {
                    display: flex;
                    min-width: 152px;
                    padding: 6px 20px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 300px;
                    background: $primary-yellow;
                    border: none;
                    font-size: 20px;
                    line-height: 30px;
                    cursor: pointer;
                }

                .reset-btn {
                    color: $primary-dark-grey;
                    text-align: center;
                    background: none;
                    border: none;
                    text-decoration: underline;
                    font-size: 20px;
                    line-height: 30px;
                    cursor: pointer;

                }

            }
        }
    }
}