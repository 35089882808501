@use "../../common/fontssetup" as *;
@import '../../common/variables';

.lastest-news-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 40px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .mb-page-list-btn {
        display: none;

        @include sm-desktop {
            box-sizing: border-box;
            background: $primary-beige;
            position: fixed;
            z-index: 100;
            bottom: 70px;
            width: calc(100% - 40px);
            margin: 0 20px;
            padding: 10px 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 50px;
            background: var(--Beige, #F4F0E8);
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .content-section {
        // padding: 60px 0 ;
        display: flex;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        .page-list-section {
            width:30%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;

            @include sm-desktop {
                box-sizing: border-box;
                position: fixed;
                top: 100%;
                left: 0;
                transition: all 1s ease;
                background: $primary-beige;
                width: 100%;
                height: 100vh;
                z-index: 1000;
                padding: 40px;
                padding-bottom: 20px;

                &.active {
                    top: 0;
                    transition: all 1s ease;
                }
            }

            .close-section {
                text-align: right;
            }

            .sub-page-section {

                @include sm-desktop {
                    box-sizing: border-box;
                    position: fixed;
                    top: 0;
                    left: 100%;
                    transition: all 1s ease;
                    background: $primary-beige;
                    width: 100%;
                    height: 100vh;
                    z-index: 11;
                    padding: 40px;
                    padding-bottom: 20px;
                    overflow: auto;

                    &.active {
                        left: 0;
                        transition: all 1s ease;
                    }
                }

                .sub-page-title-section {
                    display: none;

                    @include sm-desktop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 30px;
                    }

                    .title-name {
                        font-size: 20px;
                        line-height: 32px;
                        font-weight: 500;
                    }
                }
            }

            .page-list {
                list-style-type: none;
                // position: sticky;
                // top: 15%;

                @include sm-desktop {
                    // position: relative;
                    // top: 0;
                    // padding: 0;
                    margin: 0;
                }

                .sub-page-list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                }

                .more-btn {
                    margin: 20px 0;
                    padding: 6px 20px;
                    border-radius: 300px;
                    background: $primary-sand2;
                    color: $primary-white;
                    font-size: 20px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    cursor: pointer;

                    svg {display: block;}
                }

                .page-list-item {
                    box-sizing: border-box;

                    &.active {
                        .title {
                            color: $primary-sand;
                        }
                    }
                    
                    .page-link {
                        text-decoration: none;
                        
                        &:hover {
                            color: $primary-sand;
                            text-decoration: underline;
                            .title { color: $primary-sand; }
                        }
                    }

                    .title {
                        box-sizing: border-box;
                        padding: 20px 0;
                        color: $primary-black;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        border-bottom: 2px solid $primary-sand;
                        white-space: nowrap;

                        @include sm-desktop {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .icon {
                            display: none;

                            @include sm-desktop {
                                display: block;
                                svg {
                                    display: block;
                                }
                            }
                        }
                    }


                    .page-item {
                        box-sizing: border-box;
                        padding: 20px 0;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        border-bottom: 2px solid $primary-sand;
                        &.item-hidden {
                            display: none;
                        }

                        @include sm-desktop {
                            font-size: 20px;
                            line-height: 32px;
                            font-weight: 500;
                        }


                        .page-link {
                            text-decoration: none;
                            color: $primary-black;
                            
                            &.active {
                                color: $primary-sand;
                            }
                        }
                    }

                }
            }
        }

        .content-details-section {
            width: 70%;
            box-sizing: border-box;
            padding: 0 40px;
            padding-top: 60px;
            padding-bottom: 100px;

            &.beige-bg {
                background: $primary-beige;
            }
            

            @include sm-desktop {
                width: 100%;
                padding: 60px 24px 100px;
            }

            .content-desp {
                font-size: 18px;
                line-height: 28px;

                p {
                    margin: 0;
                }

                strong {
                    font-weight: 700;
                }

            }

            .title {
                @include title-font;
                font-size: 48px;

            }
            
            .line-section {
                padding: 24px 0;
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            .lastestnews-item-list {
                padding-top: 40px;
                width: 100%;
                display: block;

                &.no-slider {
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    grid-template-rows: auto;
                    column-gap: 40px;
                    row-gap: 40px;

                    @include sm-desktop {
                        grid-template-columns: 1fr;
                    }
                }

                .lastestnews-item {
                    overflow: hidden;
                    border-radius: 50px;
                    background: $primary-beige;

                    .item-img-container {
                        max-height: 30vh;
                        overflow: hidden;

                        .item-image {
                            display: block;
                            width: 100%;
                        }
                    }

                    .item-content {
                        padding: 30px 40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        row-gap: 15px;

                        .item-title {
                            height: 64px;
                            font-size: 24px;
                            line-height: 32px;
                            font-weight: 500;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        
                        .item-desp {
                            height: 112px;
                            font-size: 18px;
                            line-height: 28px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }

                        .rm-btn {
                            margin-right: auto;
                            padding: 6px 20px;
                            background: $primary-sand;
                            border-radius: 300px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            column-gap: 10px;
                            color: $primary-white;
                            text-decoration: none;
                            cursor: pointer;

                            .btn-name {
                                color: $primary-white;
                                font-size: 20px;
                                line-height: 30px;
                                font-weight: 500;
                            }

                            .btn-icon {
                                svg {
                                    display: block;
                                    path { fill: $primary-white;}
                                }
                            }

                            &:hover {
                                background-color: $primary-white;
                                .btn-name {
                                    color: $primary-black;
                                }
                                .btn-icon {
                                    svg { path { fill: $primary-black;} }
                                }
                            }

                        }
                    }
                }

                
            }

        }
    }

    .recommend-section {
        box-sizing: border-box;
        width: 100%;
        padding: 60px 40px;
        background: $primary-beige;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
        @include sm-desktop {
            padding: 60px 24px;
        }

        .section-title {
            @include title-font;
            font-size: 48px;
        }

        .recommend-item-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include sm-desktop {
                grid-template-columns: 1fr;
            }
        }

    }

}