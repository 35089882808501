@use "../../common/fontssetup" as *;
@import '../../common/variables';


.App:has(.migration-map-page-container) {
    background-color: $primary-beige;
}

.migration-map-page-container {

    .migration-map-section {
        box-sizing: border-box;
        // padding: 50px;
        position: relative;
        
        .section-details-container{
            box-sizing: border-box;
            max-width: 1440px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 40px;
            padding: 100px 40px;
            padding-top: calc(100px + 50px);

            @include sm-desktop {
                padding-left: 24px;
                padding-right: 24px;
            }
        }


        .section-title {
            @include title-font;
            font-size: 48px;
        }
    }

    @include sm-desktop {
        .contactus-form-section {
            padding-bottom: 100px;
        }
    }
}