// Variables

$text-line-height: 26px;
$text-weight: 400;
$text-size-content: 22px;
$text-size-footer: 20px;
$text-size-footer-title: 24px;

$page-section-top-bottom-padding:60px;
$page-section-left-right-padding:40px;
$page-banner-height: calc(70vh + 100px);


$primary-black: #000;
$primary-dark-grey: #959595;
$primary-grey: #F0F0F0;
$primary-brown: #564C4A;
$primary-dark-brown: #524538;
$primary-yellow: #FED65D;
$primary-beige: #F4F0E8;
$primary-beige-menu: #C2B280;
$primary-sand: #BC9357;
$primary-sand2: #D9B783;
$primary-white: #FFFFFF;
$error-red: #F00;

$sm-mobile-width:375px;
$tablet-width: 768px;
$sm-desktop-width: 1024px; 

@mixin sm-mb {
  @media (max-width: #{$sm-mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$sm-desktop-width}) {
    @content;
  }
}

@mixin sm-desktop {
  @media (max-width: #{$sm-desktop-width}) {
    @content;
  }
}

.show-mb {
    display: none;

    @include tablet {
        display: block;
    }
    @include sm-desktop  {
        display: block;
    }
}
.show-desktop {

    @include tablet {
        display: none;
    }
    @include sm-desktop  {
        display: none;
    }
}

@import './fonts';



body {
    // font-family: "Roboto";
    // font-family: "cwTeXYen","Nunito", sans-serif;
    font-family: "Karla","Georgia", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    
    &:has(.has-overlay) {
        overflow: hidden;
    }
}
