@use "../../common/fontssetup" as *;
@import '../../common/variables';

.aboutus-whoweare-page-container {

    .banner-section {
        width: 100%;
        height: $page-banner-height;
        background-size: cover;
        background-position: center;
        position: relative;

        .banner-text-section {
            color: $primary-white;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 40%;
            position: absolute;
            bottom: 24px;
            left: 40px;

            @include sm-desktop {
                width: calc(100% - 48px);
                left: 24px;
            }
    
            .page-title {
                display: flex;
                align-items: center;
    
                .page-name {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
    
            .sub-title {
                @include title-font;
                font-size: 48px;
            }
    
            .desp {
                text-transform: uppercase;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .box-box-section {
        max-width: 1440px;
        margin: auto;
        padding: 60px 40px;
        display: flex;
        align-items: center;

        @include sm-desktop {
            flex-direction: column;
            row-gap: 28px;
            padding: 40px 24px;

            &:last-child {
                padding-bottom: 100px;
            }
        }

        .img-box {
            width: calc(30% + 120px);
            border-radius: 50px;
            overflow: hidden;

            @include sm-desktop {
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .text-box {
            width: 70%;
            box-sizing: border-box;
            padding: 170px 60px;
            border-radius: 50px;

            @include sm-desktop {
                width: 100%;
                padding: 50px 30px;
            }
            
            .box-line {
                padding: 48px 0;
                
                @include sm-desktop {
                    padding: 20px 0;
                }
    
                .line {
                    width: 10%;
                    height: 1px;
                    background-color: $primary-sand;
                }
            }

            .box-title {
                @include title-font;
                font-size: 48px;

                @include sm-desktop {
                    font-size: 32px;
                }
            }

            .box-quote {
                padding-bottom: 48px;
                font-size: 24px;
                font-weight: 32px;

                @include sm-desktop {
                    padding-bottom: 20px;
                    font-size: 20px;
                }
            }
            .box-desp {
                font-size: 18px;
                font-weight: 28px;

                @include sm-desktop {
                    font-size: 16px;
                }

                p {
                    margin: 0;
                }
            }

        }
    }
    .info-destinations-section {

        .left-box {
            margin-right: -120px;
            z-index: 10;

            @include sm-desktop {
                margin-right: 0px;
            }
        }

        .right-box {
            background: $primary-beige;
            padding-left: 170px;
            
            @include sm-desktop {
                padding-left: 50px;
            }

        }

    }
    .info-travel-section {

        .left-box {
            background: $primary-sand2;
            padding-right: 170px;

            @include sm-desktop {
                padding-right: 60px;
                order: 2;
            }
            

        }

        .right-box {
            margin-left: -120px;
            z-index: 10;
            
            @include sm-desktop {
                margin-left: 0px;
                order: 1;
            }
        }

    }

}