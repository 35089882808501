@use "../../common/fontssetup" as *;
@import '../../common/variables';

.header-container {
    font-family: 'MinionPro';
    width: 100%;
    padding: 15px 40px;
    margin-bottom: -100px; //height of the scroll up menu
    box-sizing: border-box;

    position: relative;
    top: 0;
    z-index: 101;
    // flex-wrap: wrap;
    // row-gap: 15px;


    @include sm-desktop {
        padding: 15px 24px;
    }

    &.mb-subpage {
        background-color: rgba($color: $primary-white, $alpha: 1);
        margin-bottom: 0;

        .contactus-btn {
            color: $primary-sand;
            border-color: $primary-sand;
        }

        .has-overlay {
            .contactus-btn {
                border: 1px solid $primary-white;
                color: $primary-white;
            }
        }

        // .menu-section {
        //     background-color: $primary-beige;
        //     color: $primary-black;
            
        //     .page-section .page-item {
        //         .page-icon {
        //             path {
        //                 fill: $primary-black;
        //             }
        //         }

        //         .page-name {
        //             color: $primary-black;
        //         }
        //     }

        //     .sub-page-section {
        //         background-color: $primary-beige;
        //         color: $primary-black;

        //         .mb-subpage-section {
        //             color: $primary-black;
        //         }

        //         .back-icon, .page-icon {
        //             path { fill: $primary-black;}
        //         }
        //         .sub-page-list {
        //             background-color: $primary-beige;

        //             .back-icon {
        //                 path { fill: $primary-black;}
        //             }
        //             .list-item .page-item-link {
        //                 color: $primary-black;
        //             }
        //         }
        //     }
        // }

        // .map-btn-section {
        //     .map-btn {
        //         border: 1px solid $primary-sand;
        //         color: $primary-black;

        //         .map-icon {
        //             path { fill: $primary-sand;}
        //         }
        //     }
        // }
    }

    &.mb-scroll {
        background: rgba($color: $primary-white, $alpha: 1);

        .contactus-btn {
            color: $primary-sand;
            border-color: $primary-sand;
        }
    }
    
    
    &.scroll-down-version {
        background: rgba($color: $primary-white, $alpha: 1);
        position: absolute;
        border-radius: 0px 0px 30px 30px;
        box-shadow: 2px 2px 4px #ccc;

        &.no-radius {
            border-radius: none;
        }

        .menu-section {
            .page-section .page-item{
                color: $primary-black;
    
                .page-icon {
                    path {
                        fill: $primary-black;
                    }
                }
            }
            .lang-item {
                color: $primary-black;

                .underline {
                    background-color: $primary-black;
                }
            }
        }

        .map-btn {
            border-color: $primary-sand !important;
            .map-icon {
                path {
                    fill: $primary-sand;
                }
            }
        }

        .contactus-btn.show-desktop {
            border-color: $primary-sand !important;
        }
    }

    .mb-menu-section {

        @include sm-desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .right-section {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }
    }

    .header-logo-w {

        .header-logo-w-img {
            display: block;
            height: 50px;
        }
    }

    .contactus-btn {
        padding: 6px 20px;
        display: flex;
        column-gap: 10px;
        border-radius: 300px;
        border: 1px solid $primary-white;
        color: $primary-white;
        text-decoration: none;
        justify-self: flex-end;
        font-size: 16px;
        text-transform: uppercase;

        &:hover {
            color: $primary-white !important;
            background-color: $primary-sand;
            border-color: $primary-sand !important;
            
        }

        @include sm-desktop {
            color: $primary-white !important;
            background-color: $primary-sand;
            border-color: $primary-sand !important;
        }
    }

    .map-btn {
        padding: 6px 20px;
        display: flex;
        column-gap: 10px;
        border-radius: 300px;
        border: 1px solid $primary-white;
        color: $primary-white;
        text-decoration: none;
        justify-self: flex-end;
        font-size: 16px;

        &.show-desktop {
            @include sm-desktop {
                display: none;
            }
        }

        &:hover {
            color: $primary-sand;
            text-decoration: underline;
        }

        .map-icon {
            width: 24px;
            height: 24px;
        }

        .name {
            white-space: nowrap;
            font-size: 20px;
            line-height: 28px;
        }
        

    }


    .line-section {
        width: 100%;
        padding: 14px 0;
            
        &.hide {
            display: none;
        }

        .line {
            width: 100%;
            height: 2px;
            background: $primary-white;
            opacity: 0.3;
        }
    }

    .header-logo {
        width: 85px;

        .header-logo-img {
            width: 100%;
            display: block;
        }
    }
    
    .menu-section {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        column-gap: 20px;
        overflow: hidden;
        width: 100%;

        @include sm-desktop  {
            display: grid;
            grid-template-rows: max-content;
            grid-template-columns: 1fr;
            background: $primary-beige-menu;
            color: $primary-white;
            width: 100%;
            height: 100vh;
            box-sizing: border-box;
            padding: 15px 24px;
            position: fixed;
            z-index: 100;
            top: 0;
            left: 110%;
            transition: all 1s ease;

            &.active-menu {
                // display: none;
                left: 0;
                transition: all 1s ease;
            }
        }

        .top-section {
            display: none;

            @include sm-desktop  {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;
        
                .right-section {
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                }
                .header-close-btn {
                    width: 24px;
                }
            }
        }
        
        .social-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &.hide {
                display: none;
            }

            @include sm-desktop {
                order: 4;
                flex-wrap: wrap;
            }

            .ext-link-img-btn-icon {
                width: 40px;
                display: block;
            }


            .info-list {
                display: flex;
                align-items: center;
                column-gap: 20px;

                @include sm-desktop {
                    width: 100%;
                    order: 4;
                    flex-direction: column-reverse;
                    text-align: center;
                    justify-content: center;
                }
            }
            .mb-lang-item {
                display: none;
                position: relative;
                
                @include sm-desktop {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    column-gap: 20px;
                    width: 50%;
                    order: 2;
                }

                .lang-item {
                    position: relative;

                    &.active {
                        display: block;
                        .underline {
                            display: block;
                        }
                    }
                }

                .underline {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    display: none;
                }
            }
            .line {
                display: none;

                @include sm-desktop {
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $primary-white;
                    margin: 30px 0;
                    order: 3;
                }
            }
            .media-list {
                display: flex;
                align-items: center;
                column-gap: 20px;

                @include sm-desktop {
                    width: 50%;
                    order: 1;
                }

                .ext-link-img-btn-icon {
                    width: 24px;
                    display: block;
                }
            }

            .phone-item, .mail-item {
                display: flex;
                align-items: center;
                text-decoration: none;
                column-gap: 10px;
            }
            .phone-num, .email-info {
                font-size: 20px;
                color: $primary-white;
            }

            // @include sm-desktop {
            //     display: none;
            // }
        }
        

        .page-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            
            @include sm-desktop  {
                flex-direction: column;
                row-gap: 40px;
                align-items: center;
                order: 2;
                padding-top: 45px;
                align-self: flex-start;
                box-sizing: border-box;
            }

            .page-item {
                color: $primary-white;
                text-decoration: none;
                display: flex;
                column-gap: 10px;
                cursor: pointer;

                &:last-child {
                    padding-right: 0;
                }
                
                &.sub-menu-active {
                    color: $primary-sand;
                    text-decoration: underline;

                    .page-icon {
                        transform: rotate(180deg);

                        path {
                            fill: $primary-sand;
                        }
                    }
                }

                &:hover {
                    color: $primary-sand;
                    text-decoration: underline;

                    .page-icon {
                        transform: rotate(180deg);

                        path {
                            fill: $primary-sand;
                        }
                    }
                }

                @include sm-desktop {
                    width: 100%;
                    justify-content: space-between;
                }

                .page-icon {
                    @include sm-desktop {
                        order: 2;
                        transform: rotate(-90deg);
                    }
                }

                .page-name {
                    white-space: nowrap;
                    font-size: 18px;
                    line-height: 28px;
                    text-transform: uppercase;

                    @include sm-desktop {
                        order: 1;
                        font-size: 20px;
                        line-height: 32px;
                        font-weight: 500;
                    }
                }

                
                &.btn-type {
                    padding: 6px 20px;
                    display: flex;
                    column-gap: 10px;
                    border-radius: 300px;
                    border: 1px solid $primary-white;

                    @include sm-desktop {
                        display: none;
                    }
                }
            }

            .lang-item {
                @include sm-desktop {
                    display: none;
                }
            }
        }

        .sub-page-section {
            box-sizing: border-box;
            width: 100%;
            padding-top: 15px;
            display: none;

            &.active {
                display: block;
            }

            @include sm-desktop {
                position: fixed;
                top: 0px;
                display: flex;
                flex-direction: column;
                row-gap: 40px;
                padding: 24px;
                background: $primary-beige-menu;
                height: 100vh;

                left: 110%;
                transition: all 1s ease;

                &.mb-active {
                    left: 0;
                    transition: all 1s ease;
                }
            }

            .back-icon {
                svg {
                    display: block;
                }
            }

            .mb-subpage-section {
                color:$primary-white;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
                padding-bottom: 30px;
                box-sizing: border-box;
                height: 100%;
                overflow: hidden;
                display: none;

                @include sm-desktop {
                    display: block;
                }

                .title-name {
                    text-transform: uppercase;
                }

                .subpage-top-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 65px;

                    
                    .header-close-btn {
                        width: 24px;
                    }
                }

                .subpage-section {
                    display: flex;
                    flex-direction: column;
                    row-gap: 40px;
                    box-sizing: border-box;
                    height: calc(100% - 80px);
                    overflow-x: hidden;
                    overflow-y: auto;

                    .subpage-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-decoration: none;
                        color: $primary-white;

                        .page-icon {
                            svg {
                                display: block;
                                transform: rotate(-90deg);
                            }
                        }
                        
                        .item-details {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            text-decoration: none;
                            color: $primary-white;
                            

                            .page-icon {
                                svg {
                                    display: block;
                                    transform: none;
                                }

                                &.sub-list-active {
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }

                    &.mb-sub-page-list {
                        .subpage-item {
                            display: block;
                        }

                        .mb-sub-page-list {
                            display: none;
                            padding-top: 20px;
                            flex-direction: column;
                            row-gap: 20px;
                            align-items: flex-start;
                            padding-left: 15px;

                            &.active {
                                display: flex;
                            }

                            .page-item-link {
                                text-decoration: none;
                                color: $primary-white;
                                font-size: 20px;
                                line-height: 32px;

                                &:hover {
                                    color: $primary-sand;
                                }
                            }

                        }
                    }
                }
            }

            .sub-page-list {
                font-size: 18px;
                line-height: 28px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-top: 1px dashed $primary-sand;
                padding: 15px 40px;

                &:nth-child(2) {
                    border-top: 1px solid $primary-sand;
                }

                &:last-child {
                    padding-bottom: 0;
                }


                @include sm-desktop {
                    padding: 24px;
                    border-top: 0px !important;
                    box-sizing: border-box;
                    position: fixed;
                    top: 0;
                    background: $primary-dark-brown;
                    width: 100%;
                    height: 100vh;
                    display: block;
                    left: 110%;
                    transition: all 1s ease;

                    &.active {
                        left: 0;
                        transition: all 1s ease;
                    }
                }

                .list-title {
                    padding-bottom: 15px;
                    font-weight: 700;

                    .back-icon, .header-close-btn {
                        display: none;
                    }

                    .header-close-btn {
                        width: 24px;
                    }

                    @include sm-desktop {
                        padding-bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 65px;
                        font-size: 20px;
                        line-height: 32px;
                        font-weight: 500;

                        .back-icon, .header-close-btn {
                            display: block;
                        }
                    }
                }
                .list-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 30px;

                    @include sm-desktop {
                        flex-direction: column;
                        row-gap: 40px;
                        align-items: flex-start;
                    }

                    .page-item-link {
                        text-decoration: none;
                        color: $primary-black;

                        @include sm-desktop {
                            color: $primary-white;
                            font-size: 20px;
                            line-height: 32px;
                        }

                        &:hover {
                            color: $primary-sand;
                            text-decoration: underline;
                        }
                    }
                }

                
                &.africa-sub-page,.row-sub-page {
                    .page-item-link {
                        text-transform: uppercase;
                    }
                }
            }

            &.destination-sub-page {
                .item-name {text-transform: uppercase;}   
            }
        }

        .lang-top-line {
            order:4;
        }
        .lang-bottom-line {
            order:6;
        }

        .lang-section {
            position: relative;
            // display: flex;
            display: none;
            
            @include sm-desktop {
                padding: 0 20px;
            }

            @include sm-desktop  {
                order: 5;
                flex-wrap: wrap;
                row-gap: 20px;
                justify-content: center;
                align-items: center;
                
                .lang-mb-line {
                    flex: none;
                    width: 100%;
                    
                    svg {
                        width: 100%;
                    }
                }
                .lang-bottom-line {
                    order: 4;
                }
            }
        }
        

        .lang-item {
            position: relative;
            cursor: pointer;
            font-size: 20px;
            line-height: 28px;
            color: $primary-white;
            display: block;
            
            @include sm-desktop {
                margin-right: 20px;
            }

            .underline {
                display: none;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 1px;
                background-color: $primary-white;
            }

            &.active {
                display: none;
            }

            &:hover {
                .underline {
                    display: block;
                }
            }

        }


        .social-btn-container {
            order: 8;
            align-items: center;
            column-gap: 20px;
            display: none;

            @include sm-desktop  {
                display: flex;
            }
        }
    }
    .map-btn-section {
        width: 100%;

        .map-btn {
            justify-content: center;
        }
        @include sm-desktop {
            order: 3
        }
    }

}